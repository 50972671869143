import React from "react";
import "./index.css";

export const LabelInput = ({ children, number, className }) => {
  return (
    <div className={className ? "labelInput " + className : "labelInput"}>
      <div className="numberLabel">
        {number}
        <svg height="10" width="11">
          <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path>
          <path d="M8 4v2H0V4z"></path>
        </svg>
      </div>

      <h3>{children}</h3>
    </div>
  );
};
