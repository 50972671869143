const horarioOptions = [
  { value: "Por la mañana", text: "Por la mañana" },
  { value: "Por la tarde", text: "Por la tarde" },
  { value: "Por la noche", text: "Por la noche" },
  { value: "Cualquier horario", text: "Cualquier horario" }
];

const usadoOptions = [
  {
    option: "a",
    value: "Todo en cuotas",
    defaultChecked: true
  },
  {
    option: "b",
    value: "Entregando mi usado y cuotas",
    defaultChecked: false
  },
  {
    option: "c",
    value: "Parte en efectivo y resto en cuotas",
    defaultChecked: false
  }
];

export { horarioOptions, usadoOptions };
