import "./index.css";
import { useState } from "react";
import arrow from "../../assets/dropdown.svg";

export default function InputForm({
  type,
  name,
  placeholder,
  value,
  handleChangeInput,
  required,
  options,
  error = '',
  onClick
}) {
  const [focused, setFocused] = useState(false);
  const [errorInput, setErrorInput] = useState(error);
  const handleFocus = (e) => {
    setFocused(true);
  };


  const [isRequired, setIsRequired] = useState(required);

  const handleInput = (e) => {
    const value = e.target.value;
    setIsRequired(value.trim() !== '');
    const isValid = validateEmail(value);
    e.target.setCustomValidity(isValid ? '' : 'Por favor ingrese un email válido');
    setErrorInput(isValid ? '' : 'Por favor ingrese un email válido');
    handleChangeInput(e);
};

  function validateEmail(email) {
    if (!email || email.trim() === '') {
        return true;
    }
    const cleanEmail = email.trim();
    if (cleanEmail.endsWith('.')) {
        return false;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const invalidPatterns = ['.@', '...', '@.', '@com'];
    for (const pattern of invalidPatterns) {
        if (cleanEmail.includes(pattern)) {
            return false;
        }
    }
    return emailRegex.test(cleanEmail);
}



  if (type === "text") {
    return (
      <div className="inputDiv">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChangeInput}
          required={required}
          onBlur={handleFocus}
          focused={focused.toString()}
        />
        <span className="errorInput">{errorInput}</span>
      </div>
    );
  }

  if (type === "email") {
    return (
        <div className="inputDiv">
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleInput}
                required={isRequired}
                onBlur={handleFocus}
                focused={focused.toString()}
            />
            <span className="errorInput">{errorInput}</span>
        </div>
    );
}

  if (type === "select") {
    return (
      <div className="inputDiv">
        <select
          type={type}
          name={name}
          onChange={handleChangeInput}
          required={required}
          onBlur={handleFocus}
          focused={focused.toString()}
          defaultValue={"DEFAULT"}
        >
          <option disabled={true} hidden={true} value="DEFAULT">
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              disabled={option.disabled}
              hidden={option.hidden}
              key={option.value}
              value={option.value}
            >
              {option.text}
            </option>
          ))}
        </select>
        <img className="dropdown" src={arrow} alt="Arrow" />

        <span className="errorInput">{errorInput}</span>
      </div>
    );
  }

  // Cards Radio Button

  if (type === "cardsradio") {
    return (
      <div className="inputDiv">
        <div className="radioDivCard">
          {options.map((option) => (
            <div key={option.nombre}>
              <input
                id={option.nombre}
                type="radio"
                name={name}
                value={option.nombre}
                onChange={handleChangeInput}
                defaultChecked={option.defaultChecked}
                onClick={onClick}
              />
              <label className="radioCard" htmlFor={option.nombre}>
                <img src={option.img} alt={option.nombre} />
                Plan {option.nombre}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (type === "radio") {
    return (
      <div className="inputDiv">
        {/* <p className="labelForm">{placeholder}</p> */}
        <div className="radioDiv">
          {options.map((option, key) => (
            <div key={option.value}>
              <input
                id={name + key}
                type="radio"
                name={name}
                value={option.value}
                onChange={handleChangeInput}
                defaultChecked={option.defaultChecked}
              />
              <label htmlFor={name + key}>
                <span>{option.option}</span>
                {option.value}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
