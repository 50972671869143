import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useCons } from "../context/ConsContext";

const Header = () => {
  const { themeOptions } = useCons();

  return (
    <div className="header flex flexCenter flexRow">
      <div className="logo container-lg flex flexRow">
        <Link to={"/"}>
          <img src={themeOptions.brand} alt={"Logo " + themeOptions.carBrand} />
        </Link>
        <img className="logoCons" src={themeOptions.logo} alt="Consesionaria" />
      </div>
    </div>
  );
};

export default Header;
